
import {computed, defineComponent, onMounted, ref} from "vue";
import AppCore from "@/core/appcore/AppCore";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {Field} from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import reservationProductsTypetypes from "@/core/data/reservationProductsTypetypes";
import {dateConvert} from "@/core/filters/datatime";
import PrintInvoice from "@/components/invoices/invoices/PrintInvoice.vue";


export default defineComponent({
  name: "bill-step-3",
  components: {
    Field,
    PrintInvoice,
  },

  setup() {

    onMounted(() => {

      myCurRates.value = AppCore.prsCore_Invoice.objCurRate;
      myFormValues.value = AppCore.prsCore_Invoice.objFormValues;
      myAccountDetails.value = AppCore.prsCore_Invoice.objAccDetails;
      myCheckedProducts.value = AppCore.prsCore_Invoice.arrCheckedProducts;


      if(myCheckedProducts.value.length>0){
        for(let i=0; i<myCheckedProducts.value.length; i++){
          let eachCheckedProduct = myCheckedProducts.value[i];
          //objAmount.value.AllTotalCost += parseFloat(eachCheckedProduct.Price);
          objAmount.value.AllTotalCost += libTools.method.getCurParity2((myCurRates.value as any), eachCheckedProduct.TotalPrice, eachCheckedProduct.Cur, myFormValues.value.CurType, eachCheckedProduct.SecretProfit);
          objAmount.value.VatFreePrice = objAmount.value.AllTotalCost / 1.05;
          objAmount.value.VatPrice =  objAmount.value.AllTotalCost - (objAmount.value.AllTotalCost / 1.05);
        }
      }


      if((myFormValues.value as any).GroupType=== "GROUPALL"){
        for(let i=0; i<myCheckedProducts.value.length; i++){
          let eachCheckedProduct = myCheckedProducts.value[i];
          resultAll.value.Price += libTools.method.getCurParity2((myCurRates.value as any), eachCheckedProduct.TotalPrice, eachCheckedProduct.Cur, myFormValues.value.CurType, eachCheckedProduct.SecretProfit);
          resultAll.value.Count++;
          resultAll.value.Desc = myFormValues.value.InvoiceDescription + " " + "Hizmet Bedeli";
          resultAll.value.ProductIDs.push(eachCheckedProduct.ID);
        }
      }


      if((myFormValues.value as any).GroupType=== "NONGROUP"){
        for(let i=0; i<myCheckedProducts.value.length; i++){
          let eachCheckedProduct = myCheckedProducts.value[i];
          resultNonGroups.value.push({
            ProductType: eachCheckedProduct.ProductType,
            Title: eachCheckedProduct.Title,
            Desc:libTools.method.getCoreDataByValue(reservationProductsTypetypes.TYPES, eachCheckedProduct.ProductType).TitleLower + " Hizmet Bedeli",
            FinishDate: eachCheckedProduct.FinishDate,
            StartDate: eachCheckedProduct.StartDate,
            Price: libTools.method.getCurParity2((myCurRates.value as any), eachCheckedProduct.TotalPrice, eachCheckedProduct.Cur, myFormValues.value.CurType, eachCheckedProduct.SecretProfit),
            ADTCount: eachCheckedProduct.ADTCount,
            CHDCount: eachCheckedProduct.CHDCount,
            INFCount: eachCheckedProduct.INFCount,
            ProductIDs: [eachCheckedProduct.ID],
            Count : 1,

          });
        }
      }


      if((myFormValues.value as any).GroupType=== "GROUPBYPRODUCT"){
        for(let i=0; i<myCheckedProducts.value.length; i++){
          let eachCheckedProduct = myCheckedProducts.value[i];
          let foundedGroup = getElementByGroupType(eachCheckedProduct.ProductType);
          if (foundedGroup === undefined){
            resultGroups.value.push({
              ProductType : eachCheckedProduct.ProductType,
              StartDate : eachCheckedProduct.StartDate,
              FinishDate : eachCheckedProduct.FinishDate,
              ADTCount: 0,
              CHDCount: 0,
              INFCount: 0,
              Price : libTools.method.getCurParity2((myCurRates.value as any), eachCheckedProduct.TotalPrice, eachCheckedProduct.Cur, myFormValues.value.CurType, eachCheckedProduct.SecretProfit),
              Count : 1,
              Desc:myFormValues.value.InvoiceDescription + " " +libTools.method.getCoreDataByValue(reservationProductsTypetypes.TYPES, eachCheckedProduct.ProductType).TitleLower + " Hizmet Bedeli",
              ProductIDs: [eachCheckedProduct.ID],
            });
          } else {
            foundedGroup.Price += libTools.method.getCurParity2((myCurRates.value as any), eachCheckedProduct.TotalPrice, eachCheckedProduct.Cur,  myFormValues.value.CurType, eachCheckedProduct.SecretProfit);
            foundedGroup.Count++;
            foundedGroup.ProductIDs.push(eachCheckedProduct.ID);
          }
        }
      }


      function getElementByGroupType(prmGroupType) {
        for (let i=0; i<resultGroups.value.length; i++){
          let eachResultGroup = resultGroups.value[i];
          if(eachResultGroup.ProductType === prmGroupType){
            return eachResultGroup
          }
        }
        return  undefined;
      }

    });


    const myFormValues = ref<any>({});
    const myAccountDetails = ref({});
    const myCheckedProducts = ref ([]);
    const myCurRates = ref ({});
    const myOptimazedCurRates = ref ({CurUSD:0, CurTRY:0, CurEUR:0, CurSTG:0});
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const resultAll = ref({Price:0, Count:0, Desc:"", ProductIDs:[], FinishDate: new Date(), StartDate: new Date(), ADTCount:0, CHDCount: 0, INFCount: 0, ProductType:"", });
    const resultGroups = ref([]);
    const resultNonGroups = ref([]);
    const objAmount = ref({AllTotalCost:0, VatFreePrice: 0, VatPrice:0,});
    const refComp_PrintInvoice_2 = ref(null);





    function getCur() {
      if(myCheckedProducts.value.length>0){
        return myFormValues.value.CurType;
      }
      return "";
    }

    function getOptimizedCurRate() {
      if(myCheckedProducts.value.length>0){
        myOptimazedCurRates.value.CurUSD = (myCurRates.value as any).CurUSD;
        myOptimazedCurRates.value.CurTRY = (myCurRates.value as any).CurTRY;
        myOptimazedCurRates.value.CurEUR = (myCurRates.value as any).CurEUR;
        myOptimazedCurRates.value.CurSTG = (myCurRates.value as any).CurSTG;
        return myOptimazedCurRates.value;
      }
      return "";
    }


    function getAccountID() {
      if(myCheckedProducts.value.length>0){
        return myCheckedProducts.value[0].AccountID;
      }
      return "";
    }



    function getOptimizedProducts() {
      let rv = [];
      for(let i=0; i<myCheckedProducts.value.length; i++){
        let eachCheckedProduct = myCheckedProducts.value[i];
        rv.push({
          ID: parseInt(eachCheckedProduct.ID),
        });
      }
      return rv;
    }


    function getInvoiceItems(){
      if((myFormValues.value as any).GroupType=== "GROUPALL"){
        return resultAll.value;
      }
      if((myFormValues.value as any).GroupType=== "GROUPBYPRODUCT"){
        return resultGroups.value;
      }
      if((myFormValues.value as any).GroupType=== "NONGROUP"){
        return resultNonGroups.value;
      }
      return [];
    }



    const myInvoiceID = ref<number>(0);


    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      const payload1 = {
        Title: "",
        No: myFormValues.value.InvoiceNo,
        AccountID: getAccountID(),
        AccountCompanyName: (myAccountDetails.value as any).CompanyName,
        AccountAddress: (myAccountDetails.value as any).Address,
        AccountTaxOffice: (myAccountDetails.value as any).TaxOffice,
        AccountTaxNumber: (myAccountDetails.value as any).TaxNumber,
        AccountPhone: (myAccountDetails.value as any).Phone,
        GroupType: (myFormValues.value as any).GroupType,
        Date: dateConvert.method.convertToDateYM(myFormValues.value.InvoiceDate) ,
        Amount: objAmount.value.AllTotalCost,
        AllTotalCost: objAmount.value.AllTotalCost,
        VatFreePrice: objAmount.value.VatFreePrice,
        VatPrice: objAmount.value.VatPrice,
        Discount: "",
        Cur: getCur(),
        CurRate: getOptimizedCurRate(),
        Description: myFormValues.value.InvoiceDescription,
        ReservationProducts: getOptimizedProducts(),
        InvoiceItems: getInvoiceItems(),

      }


      store.dispatch(Actions.INVOICES_INSERT_WITH_ITEMS, payload1)
          .then((prmData) => {

            myInvoiceID.value = prmData.data.InvoiceID;

            console.log(myInvoiceID.value);
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde kaydetme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function (prmData) {
              document.getElementById('save-invoice').style.display = 'none';
              document.getElementById('action-invoice').style.display = 'block';
            });
            submitButtonRef.value.disabled = false;
            submitButtonRef.value.setAttribute("data-kt-indicator", "off");
          }).catch(() => {
        Swal.fire({
          text: store.getters.getInvoicesErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
    }

    function clickShowInvoice() {
      (refComp_PrintInvoice_2.value as any).showComp("none");

    }




    return {
      libTools,
      reservationProductsTypetypes,
      myCheckedProducts,
      submitButtonRef,
      myFormValues,
      myCurRates,
      resultAll,
      resultGroups,
      resultNonGroups,
      objAmount,
      myAccountDetails,
      myInvoiceID,
      refComp_PrintInvoice_2,
      submit,
      clickShowInvoice,
    };
  },
});
